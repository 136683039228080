import React, { useState } from "react";
import { useOnboarding } from "../onboarding/OnboardingContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./styles/consent.css";

export default function DataConsent() {
  const { onboardingData, nextStep, updateOnboardingData } = useOnboarding();
  const [next, setNext] = useState(false);

  const enableNext = () => {
    setNext(true);
    document.querySelector(".agree-btn").style.backgroundColor = "black";
  };

  const handleNext = () => {
    updateOnboardingData({ data_consent: true });
    nextStep();
    setNext(false);
  };

  return (
    <div className="container">
      <h1 className="page-header">Data Consent</h1>
      <div className="content">
        <h3>1. Agreement Of Data</h3>
        <p>
          <strong>a.</strong> As part of the platform you must agree to have your data used{" "}
        </p>
        <h3>1. Agreement Of Data</h3>
        <p>
          <strong>a.</strong> As part of the platform you must agree to have your data used{" "}
        </p>
        <h3>1. Agreement Of Data</h3>
        <p>
          <strong>a.</strong> As part of the platform you must agree to have your data used{" "}
        </p>
        <h3>1. Agreement Of Data</h3>
        <p>
          <strong>a.</strong> As part of the platform you must agree to have your data used{" "}
        </p>
        <h3>1. Agreement Of Data</h3>
        <p>
          <strong>a.</strong> As part of the platform you must agree to have your data used{" "}
        </p>
        <h3>1. Agreement Of Data</h3>
        <p>
          <strong>a.</strong> As part of the platform you must agree to have your data used{" "}
        </p>
        <h3>1. Agreement Of Data</h3>
        <p>
          <strong>a.</strong> As part of the platform you must agree to have your data used{" "}
        </p>
        <h3>1. Agreement Of Data</h3>
        <p>
          <strong>a.</strong> As part of the platform you must agree to have your data used{" "}
        </p>
      </div>
      <div className="agree-container">
        <div className="agree-group">
          <button className="agree-btn" onClick={enableNext}></button>
          <label id="agree-name">
            <u>I Agree</u>
          </label>
        </div>
        <div className="next">
          {next && (
            <div>
              <FontAwesomeIcon className="next-button" onClick={handleNext} icon={faArrowRight} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
