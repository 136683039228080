import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import Slider from "react-slick";

import ShortnedText from "../reusables/ShortenedText";

import axios from "axios";

const api = `${process.env.REACT_APP_API}`;

export function UserProperty({ listing_id, user }) {
  const [viewMore, setViewMore] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchListingData = async () => {
      try {
        const response = await axios.post(
          api + "/dashboard/get-suggestion-listing",
          { listing_id: listing_id, user_id: user },
          { withCredentials: true }
        );

        setPropertyDetails(response.data.listing);
      } catch (error) {
        console.error("Error fetching listing data");
      } finally {
        setLoading(false);
      }
    };

    fetchListingData();
  }, [listing_id, user]);

  const toggleViewMore = () => {
    setViewMore(!viewMore);
  };

  const settings = {
    dots: true,
    infinite: propertyDetails?.photos.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!propertyDetails) {
    return <div>No property details available</div>;
  }

  return (
    <div className="property-details">
      <div className="property-header">{propertyDetails.owner}'s Listing</div>
      <div className="property-photos">
        <Slider {...settings}>
          {propertyDetails.photos?.map((photo, index) => (
            <div key={index}>
              <img src={photo} alt={`Property ${index + 1}`} />
            </div>
          ))}
        </Slider>
      </div>
      <div className="property-title">{propertyDetails.title}</div>
      <div className="break-line"></div>
      <div className={`property-description`}>
        <div className="property-details-inner-header">Description</div>
        <div className="description-content">
          <ShortnedText textData={propertyDetails.description} />
        </div>
      </div>
      <div className="break-line"></div>
      <div className="offerings-amenities">
        <div className="property-details-inner-header">Offerings/Amenities</div>
        <div className="amenities-grid">
          {propertyDetails.amenities
            ?.slice(0, viewMore ? propertyDetails.amenities.length : 3)
            .map((amenity, index) => (
              <div key={index} className="amenity">
                <div className="amenity-name">{amenity}</div>
              </div>
            ))}
        </div>
        {propertyDetails.amenities.length > 3 && (
          <button className="view-more-btn" onClick={toggleViewMore}>
            {viewMore ? "View Less" : "View More"}{" "}
            <FontAwesomeIcon icon={faChevronDown} className={`dropdown-arrow ${viewMore ? "open" : ""}`} />
          </button>
        )}
      </div>
    </div>
  );
}
