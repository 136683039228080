import { React, useState, useEffect } from "react";

import MobileNavigation from "./MobileNavbar";
import WebNavigation from "./WebNavbar";

import Account from "./Account";
import Suggestions from "./Suggestions";
import Chats from "./Messages";
import LikedBy from "./Likes";

export default function Dashboard() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [currentView, setView] = useState("profile");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleShowContent = (view) => {
    setView(view);
  };

  const dashboardContent = () => {
    switch (currentView) {
      case "Account":
        return <Account />;
      case "Suggestions":
        return <Suggestions />;
      case "Messages":
        return <Chats />;
      case "Likes":
        return <LikedBy />;

      default:
        return <Account />;
    }
  };

  return (
    <div>
      {isMobile ? (
        <MobileNavigation onLinkClick={handleShowContent} />
      ) : (
        <WebNavigation onLinkClick={handleShowContent} />
      )}
      <div>{dashboardContent()}</div>
    </div>
  );
}
