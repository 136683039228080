import React, { useState, useEffect } from "react";
import axios from "axios";

import DashedProgressBar from "../reusables/DashedProgress";
import Error from "../reusables/Error";
import { Guidelines, TermsOfServiceContent, PrivacyPolicyContent } from "../reusables/Guidelines";

import "./styles/verify.css";

const api = `${process.env.REACT_APP_API}`;
axios.defaults.withCredentials = true;

const VerificationCodeInput = ({ values, handleChange, handleKeyDown, handlePaste }) => {
  return (
    <div className="verification-code-input">
      {values.map((value, index) => (
        <input
          key={index}
          id={`code-input-${index}`}
          type="text"
          maxLength="1"
          value={value}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          className="code-input-box"
          pattern="[0-9]*"
          inputMode="numeric"
        />
      ))}
    </div>
  );
};

const Verify = ({ nextStep, formData, setError, setMessage, showError, errorMessage }) => {
  const length = 6;
  const [values, setValues] = useState(Array(length).fill(""));
  const [timer, setTimer] = useState(60);
  const [canResend, setCanResend] = useState(false);
  const [showGuidelines, setShowGuidelines] = useState(false);
  const [guidelinesTitle, setGuidelinesTitle] = useState("");
  const [guidelinesContent, setGuidelinesContent] = useState("");

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(countdown);
    } else {
      setCanResend(true);
    }
  }, [timer]);

  const handleChange = (e, index) => {
    const newValues = [...values];
    const { value } = e.target;

    if (!/^\d*$/.test(value)) {
      return; // Only allow digits
    }

    if (index > 0 && !values[index - 1]) {
      return; // Prevent typing in current box if the previous one is empty
    }

    newValues[index] = value;
    setValues(newValues);

    // Move focus to the next input box if a value is entered
    if (value && index < length - 1) {
      document.getElementById(`code-input-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (values[index]) {
        const newValues = [...values];
        newValues[index] = "";
        setValues(newValues);
      } else if (index > 0) {
        document.getElementById(`code-input-${index - 1}`).focus();
      }
    }
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData("text");
    if (!/^\d{6}$/.test(paste)) {
      return; // Only process if the pasted content is a 6-digit number
    }

    const newValues = paste.split("").slice(0, length);
    setValues(newValues);

    // Focus the last input box after paste
    document.getElementById(`code-input-${length - 1}`).focus();
  };

  const handleResendCode = async () => {
    if (canResend) {
      // Handle resend code logic here
      const request_body = { email: formData.email };
      const response = await axios.post(api + "/signup/resend-auth-code", request_body);

      if (response.data.status === 200) {
        setTimer(60);
        setCanResend(false);
        setError(false);
      } else {
        setError(true);
        setMessage(response.data.message);
      }
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();

    const code = values.join("");

    const request_body = {
      email: formData.email,
      code: code,
    };

    const response = await axios.post(api + "/signup/check-auth-code", request_body, {
      withCredentials: true,
    });

    if (response.data.status === 200) {
      await axios.post(api + "/signup/authenticate-for-onboarding", request_body);
      nextStep();
    } else {
      setError(true);
      setMessage(response.data.message);
    }
  };

  const formatTimer = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  const handleShowGuidelines = (title, content) => {
    setGuidelinesTitle(title);
    setGuidelinesContent(content);
    setShowGuidelines(true);
  };

  const handleCloseGuidelines = () => {
    setShowGuidelines(false);
  };

  return (
    <>
      <div className="main-container">
        <div className="container-info">
          <div className="header-container">
            <h2 className="signup-header">Verify your email</h2>
          </div>
          <DashedProgressBar activeIndex={3} />
          <div className="text-wrapper">
            <p style={{ fontFamily: "Roboto", paddingTop: "0px", margin: 0 }}>
              We've sent a 6-digit code to <br />
              <b>{formData.email}</b>
            </p>
          </div>
          <form onSubmit={handleVerify}>
            <VerificationCodeInput
              values={values}
              handleChange={handleChange}
              handleKeyDown={handleKeyDown}
              handlePaste={handlePaste}
            />
            {showError && <Error message={errorMessage} />}
            <button className="sign-up-continue">Verify</button>
          </form>
          <div className="text-wrapper">
            <p style={{ fontFamily: "Inter" }}>
              Didn't receive an email?{" "}
              <button
                onClick={handleResendCode}
                disabled={!canResend}
                style={{
                  background: "none",
                  border: "none",
                  color: canResend ? "black" : "grey",
                  cursor: canResend ? "pointer" : "not-allowed",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Resend code.
              </button>
              {canResend ? null : <span className="timer-wrapper"> {formatTimer(timer)}</span>}
            </p>
          </div>
        </div>
        <div className="terms-of-services">
          <span>
            By clicking continue, you agree to our
            <p>
              <a href="#" onClick={() => handleShowGuidelines("Terms of Service", TermsOfServiceContent)}>
                {" "}
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="#" onClick={() => handleShowGuidelines("Privacy Policy", PrivacyPolicyContent)}>
                Privacy Policy
              </a>
            </p>
          </span>
        </div>
      </div>
      {showGuidelines && (
        <Guidelines title={guidelinesTitle} content={guidelinesContent} onClose={handleCloseGuidelines} />
      )}
    </>
  );
};

export default Verify;
