import { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { SignUpProvider } from "./components/authentication/SignUpContext";
import { OnboardingProvider } from "./components/onboarding/OnboardingContext";

import MobileLanding from "./mobile/MobileLanding";
import WebLanding from "./web/WebLanding";
import Login from "./components/authentication/Login";
import PrivateRoute from "./PrivateRoute";
import Onboarding from "./components/onboarding/Onboarding";
import Dashboard from "./components/dashboards/Dashboard";

import Signup from "./components/authentication/SignUp";
import supabase from "./supabase/SupabaseClient";

import axios from "axios";

const api = `${process.env.REACT_APP_API}`;

function App() {
  const [user, setUser] = useState(""); // {authenticated, email, role}
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const checkAuth = async () => {
      const { data } = await axios.get(api + "/check-auth", {
        withCredentials: true,
      });

      if (data.status === 200) {
        setUser(data.user);
      }
    };

    checkAuth();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <SignUpProvider>
      <Routes>
        <Route path="/" element={isMobile ? <MobileLanding /> : <WebLanding />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/onboarding"
          element={
            <PrivateRoute>
              <OnboardingProvider>
                <Onboarding />
              </OnboardingProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="/home/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        {/* Catch-all route to redirect to the homepage */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </SignUpProvider>
  );
}

export default App;
