import React, { useState } from "react";
import "./styles/budget.css";

import { useOnboarding } from "../onboarding/OnboardingContext";

const BudgetSlider = ({ min, max }) => {
  const { updateOnboardingData } = useOnboarding();

  const [value, setValue] = useState(min);

  const handleChange = (event) => {
    setValue(event.target.value);
    updateOnboardingData({ budget: event.target.value });
  };

  return (
    <div className="budget-slider-container">
      <div className="slider-value" style={{ left: `${((value - min) / (max - min)) * 90}%` }}>
        ${value}
      </div>
      <input type="range" min={min} max={max} value={value} onChange={handleChange} className="slider" />
      <div className="slider-values">
        <span>${min}</span>
        <span>${max}</span>
      </div>
    </div>
  );
};

export default BudgetSlider;
