import { React, useState } from "react";

export default function ShortenedText({ textData }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  const words = textData.split(" ");
  if (!isExpanded && words.length >= 30) {
    return (
      <>
        {words.slice(0, 40).join(" ")}...{" "}
        <span className="view-more" onClick={toggleDescription}>
          show more
        </span>
      </>
    );
  }
  return (
    <>
      {textData}{" "}
      {isExpanded && (
        <span className="view-more" onClick={toggleDescription}>
          show less
        </span>
      )}
    </>
  );
}
