import React, { useState } from "react";
import axios from "axios";
import { useOnboarding } from "./OnboardingContext";
import "./styles/roleselect.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const api = `${process.env.REACT_APP_API}`;

export default function RoleSelect() {
  const { nextStep, updateOnboardingData } = useOnboarding();
  const [next, setNext] = useState(false);
  const [choice, setChoice] = useState();

  const enableNext = (e) => {
    setNext(true);
    setChoice(e.target.value);

    e.target.style.border = "1px solid black";
    if (e.target.value === "searcher") {
      document.querySelector(".role-selection[value='poster']").style.border = "1px solid #EAEAEA";
    } else {
      document.querySelector(".role-selection[value='searcher']").style.border = "1px solid #EAEAEA";
    }
  };

  const handleNext = async () => {
    updateOnboardingData({ role: choice });
    nextStep();
    setNext(false);

    try {
      const response = await axios.post(`${api}/onboarding/update-role`, { role: choice }, { withCredentials: true });

      if (response.data.status === 200 || response.data.status === 201) {
        console.log(response.data.message);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  return (
    <div className="role-select-body">
      <h1 className="page-header">Getting started</h1>
      <div className="prompt">Which best describes your situation ?</div>
      <div className="role-select-container">
        <div>
          <button value="searcher" onClick={enableNext} className="role-selection">
            I am looking for <strong>both</strong> a <br />
            property and roommates
          </button>
        </div>
        <div>
          <button value="poster" onClick={enableNext} className="role-selection">
            I have a property and looking <br />
            <strong>only</strong> for roommates
          </button>
        </div>
      </div>
      <div className="role-select-footer">
        {next && (
          <div className="br-next-button">
            <FontAwesomeIcon className="next-button" onClick={handleNext} icon={faArrowRight} />
          </div>
        )}
        <Circles />
      </div>
    </div>
  );
}

export const Circles = () => {
  return (
    <div className="circles">
      <div className="circle-2"></div>
      <div className="circle-1"></div>
      <div className="circle-3"></div>
    </div>
  );
};
