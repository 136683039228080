import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import supabase from "../../supabase/SupabaseClient";
import CroppingModal from "../reusables/CroppingModal";
import "./styles/propertyphotos.css";

export default function PropertyPhotosUpload({ photos, setPropertyPhotos, updateOnboardingData }) {
  const [imageSrc, setImageSrc] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
        setShowModal(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCrop = async (croppedImage) => {
    try {
      const imageBlob = await (await fetch(croppedImage)).blob();
      const fileExt = imageBlob.type.split("/")[1];
      const fileName = `${Date.now()}.${fileExt}`;
      const filePath = `public/${fileName}`;
      const { data, error } = await supabase.storage.from("property_photos").upload(filePath, imageBlob);

      if (error) throw error;

      const publicUrl = supabase.storage.from("property_photos").getPublicUrl(filePath).data.publicUrl;
      const newPhotoKey = `photo-${Date.now()}`;
      const newPhotos = { ...photos, [newPhotoKey]: publicUrl };
      setPropertyPhotos(newPhotos);
      updateOnboardingData({ photos: newPhotos });
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setShowModal(false);
    }
  };

  const fileUpload = () => {
    document.getElementById("fileInput").click();
  };

  const removePhoto = (key) => {
    const { [key]: _, ...remainingPhotos } = photos;
    setPropertyPhotos(remainingPhotos);
    updateOnboardingData({ photos: remainingPhotos });
  };

  return (
    <>
      <input id="fileInput" type="file" style={{ display: "none" }} accept="image/*" onChange={handleImageUpload} />
      <div className="photos">
        {Object.entries(photos).map(([key, imageSrc]) => (
          <div className="photo" key={key}>
            <div onClick={() => removePhoto(key)}>×</div>
            <PropertyPhoto imageSrc={imageSrc} />
          </div>
        ))}
        <div className="add-photos" onClick={fileUpload}>
          <FontAwesomeIcon className="square-plus-icon" icon={faSquarePlus} />
          <p>Add Photos</p>
        </div>
      </div>
      {showModal && <CroppingModal imageSrc={imageSrc} onCrop={handleCrop} onClose={() => setShowModal(false)} />}
    </>
  );
}

const PropertyPhoto = ({ imageSrc }) => {
  return <img src={imageSrc} alt="Property" className="property-upload-image" />;
};
