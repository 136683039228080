import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";

const api = `${process.env.REACT_APP_API}`;

function PrivateRoute({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get(api + "/check-auth", { withCredentials: true });
        setIsAuthenticated(response.data.status === 200);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, []);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Or a spinner/loading indicator
  }

  return isAuthenticated ? children : <Navigate to="/" />;
}

export default PrivateRoute;
