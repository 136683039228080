import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import "./styles/success.css";
import { Guidelines, TermsOfServiceContent, PrivacyPolicyContent } from "../reusables/Guidelines";

const Success = () => {
  const [showGuidelines, setShowGuidelines] = useState(false);
  const [guidelinesTitle, setGuidelinesTitle] = useState("");
  const [guidelinesContent, setGuidelinesContent] = useState("");

  const handleShowGuidelines = (title, content) => {
    setGuidelinesTitle(title);
    setGuidelinesContent(content);
    setShowGuidelines(true);
  };

  const handleCloseGuidelines = () => {
    setShowGuidelines(false);
  };

  return (
    <>
      <div className="main-container">
        <div className="success-container-info">
          <div className="success-icon">
            <FontAwesomeIcon icon={faCircleCheck} className="check-icon" />
          </div>
          <div className="success-header-container">
            <h2 style={{ color: "27252E", textAlign: "center" }}>
              Your account <br />
              was successfully created!
            </h2>
          </div>
          <div style={{ display: "flex", textAlign: "center", justifyContent: "Center" }}>
            <p style={{ fontFamily: "Inter", fontSize: "16px" }}>Login to start setting up your profile.</p>
          </div>
          <form>
            <button className="sign-up-continue">Login</button>
          </form>
        </div>
        <div className="terms-of-services">
          <span>
            By clicking continue, you agree to our
            <p>
              <a href="#" onClick={() => handleShowGuidelines("Terms of Service", TermsOfServiceContent)}>
                {" "}
                Terms of Services
              </a>{" "}
              and{" "}
              <a href="#" onClick={() => handleShowGuidelines("Privacy Policy", PrivacyPolicyContent)}>
                Privacy Policy
              </a>
            </p>
          </span>
        </div>
      </div>
      {showGuidelines && (
        <Guidelines title={guidelinesTitle} content={guidelinesContent} onClose={handleCloseGuidelines} />
      )}
    </>
  );
};

export default Success;
