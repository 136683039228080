import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark, faCirclePlus, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { UserProperty } from "./UserProperty";
import ExtraProfileInfo from "./ExtraProfile";
import ShortenedText from "../reusables/ShortenedText";

import axios from "axios";

import "./styles/suggestion.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const api = `${process.env.REACT_APP_API}`;

const NoMoreSuggestions = () => {
  return (
    <div className="no-more-suggestions">
      <FontAwesomeIcon className="fa-info" icon={faInfoCircle} />
      <p>You have no more suggestions, check your likes or come back later</p>
    </div>
  );
};

export default function Suggestions() {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);
  const [loading, setLoading] = useState(true);

  const [suggestionData, setSuggestions] = useState({});
  const [currentSuggestion, setCurrentSuggestion] = useState(null);
  const [potentials, setPotentials] = useState([]);

  const [profileReady, setProfileReady] = useState(false);

  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const screenUser = async () => {
      try {
        const user_ready = await axios.get(api + "/dashboard/screen-for-suggestions", { withCredentials: true });
        setProfileReady(user_ready.data.status === 200);
      } catch (error) {
        console.error("Error screening user");
      } finally {
        setLoading(false);
      }
    };

    screenUser();
  }, []);

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const response = await axios.get(api + "/dashboard/get-suggestions", { withCredentials: true });

        if (response.data.status === 200) {
          setSuggestions(response.data.suggestions);
          const potentialKeys = Object.keys(response.data.suggestions);

          setPotentials(potentialKeys);
          if (potentialKeys.length > 0) {
            setCurrentSuggestion(potentialKeys[0]);
          }
        }
      } catch (error) {
        console.error("Error fetching suggestions");
      }
    };

    if (profileReady) {
      fetchSuggestions();
    }
  }, [profileReady]);

  useEffect(() => {
    if (potentials.length > 0 && profileReady) {
      setCurrentSuggestion(potentials[0]);

      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  }, [potentials, profileReady]);

  const handleChoice = async (event) => {
    const postData = {
      suggestion: currentSuggestion,
      decision: event.currentTarget.dataset.value,
    };

    const response = await axios.post(api + "/dashboard/make-descision-on-suggestion", postData, {
      withCredentials: true,
    });

    if (response.data.status === 200) {
      if (potentials.length > 0) {
        potentials.shift();

        setCurrentSuggestion(potentials[0]);
        setPotentials([...potentials]);
      } else {
        setShowSuggestions(false);
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="dashboard-page">
      <div className="suggestions-body">
        {showSuggestions && suggestionData[currentSuggestion] ? (
          <Suggestion
            suggestionData={suggestionData}
            handleChoice={handleChoice}
            user_id={currentSuggestion}
            isLargeScreen={isLargeScreen}
          />
        ) : (
          <ExtraProfileInfo setProfileReady={setProfileReady} profileReady={profileReady} />
        )}
        {showSuggestions && suggestionData[currentSuggestion]?.role === "poster" && (
          <UserProperty user={currentSuggestion} listing_id={suggestionData[currentSuggestion]?.listing_id} />
        )}
        {showSuggestions && !isLargeScreen && (
          <div className="choice">
            <div data-value="liked" onClick={handleChoice}>
              <FontAwesomeIcon icon={faCircleCheck} />
            </div>
            <div data-value="disliked" onClick={handleChoice}>
              <FontAwesomeIcon icon={faCircleXmark} />
            </div>
          </div>
        )}
        {!showSuggestions && <NoMoreSuggestions />}
      </div>
    </div>
  );
}

function Suggestion({ suggestionData, handleChoice, isLargeScreen, user_id }) {
  return (
    <div className="suggestions-container">
      <div className="headshot-details">
        <div className="profile">
          <div className="user-picture">
            <img src={suggestionData[user_id]?.profile_picture} alt="Profile" />
          </div>
          <div className="user-name">{suggestionData[user_id]?.name}</div>
        </div>
        <div className="report-user-account">
          <u>Report Account</u>
        </div>
        <div className="program-and-age">
          <div>{suggestionData[user_id]?.program}</div>
          <div>{suggestionData[user_id]?.age}</div>
        </div>
      </div>
      <div className="break-line"></div>
      <div className="user-details">
        <div className="interest-container">
          <div className="suggestions-inner-header">Interests</div>
          <div className="user-interests">
            {suggestionData[user_id]?.interests?.map((interest, index) => (
              <div key={index} className="interest-item">
                <div className="interest-name">{interest}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="bio-container">
          <div className="suggestions-inner-header">Bio</div>
          <div>
            <ShortenedText textData={suggestionData[user_id]?.bio} />
          </div>
        </div>
      </div>
      {isLargeScreen && (
        <div className="choice">
          <div data-value="liked" onClick={handleChoice}>
            <FontAwesomeIcon icon={faCircleCheck} />
          </div>
          <div data-value="disliked" onClick={handleChoice}>
            <FontAwesomeIcon icon={faCircleXmark} />
          </div>
        </div>
      )}
    </div>
  );
}
