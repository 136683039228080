import { React, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
const api = `${process.env.REACT_APP_API}`;

function fieldsRequired(data) {
  const required = ["interests", "bio"];

  return required.every((key) => data[key] !== undefined && data[key] !== "");
}

export default function ExtraProfileInfo({ setProfileReady, profileReady }) {
  const [showModal, setShow] = useState(profileReady === false);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [bio, setBio] = useState("");
  const interests = ["Sport", "Music", "Art", "Science", "Tech", "Fashion", "Food", "Travel", "Books", "Movies"];

  const handleInterestsClick = (interest) => {
    let updatedInterests;
    if (selectedInterests.includes(interest)) {
      updatedInterests = selectedInterests.filter((item) => item !== interest);
    } else {
      updatedInterests = [...selectedInterests, interest];
    }
    setSelectedInterests(updatedInterests);
  };

  const handleNext = async () => {
    try {
      const response = await axios.post(
        api + "/dashboard/set-interests-and-bio",
        { interests: selectedInterests, bio },
        { withCredentials: true }
      );

      if (response.data.status === 200) {
        setProfileReady(true);
        setShow(false);
      }
    } catch (error) {
      console.error("Error saving interests and bio:", error);
    }
  };

  const updateBio = (e) => {
    setBio(e.target.value);
  };

  if (showModal) {
    return (
      <div className="modal-overlay">
        <div className="modal-content" style={{ padding: "30px" }}>
          <h1 style={{ marginTop: "10%", fontFamily: "Inter" }}>Just one more thing</h1>
          <div className="prompt" style={{ textAlign: "left", marginInline: "0" }}>
            Introduce yourself to others on the platform to increase your chances
          </div>
          <h3 style={{ textAlign: "left" }}>Bio</h3>
          <input
            onChange={updateBio}
            type="text"
            placeholder="What should others know about you?"
            className="text-input-primary"
          />
          <h3 style={{ textAlign: "left" }}>I Like ...</h3>
          <div className="amenities-grid">
            {interests.map((interest, index) => {
              const isSelected = selectedInterests.includes(interest);
              return (
                <div
                  key={index}
                  className={`amenity ${isSelected ? "selected" : ""}`}
                  onClick={() => handleInterestsClick(interest)}
                >
                  <FontAwesomeIcon className="add-amenity" icon={faCirclePlus} />
                  <div className="amenity-name">{interest}</div>
                </div>
              );
            })}
          </div>
          {fieldsRequired({ bio: bio, interests: selectedInterests }) && (
            <div style={{ marginTop: "5%" }}>
              <button className="sign-up-continue" onClick={handleNext}>
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  return null;
}
