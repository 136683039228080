import "./styles/dashed.css";

function DashedProgressBar({ activeIndex }) {
  return (
    <div className="progress-indicator">
      <div className={`bar ${activeIndex === 1 ? "active" : ""}`}></div>
      <div className={`bar ${activeIndex === 2 ? "active" : ""}`}></div>
      <div className={`bar ${activeIndex === 3 ? "active" : ""}`}></div>
    </div>
  );
}

export default DashedProgressBar;
