import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import DashedProgressBar from "../reusables/DashedProgress";
import Error from "../reusables/Error";
import { Guidelines, TermsOfServiceContent, PrivacyPolicyContent } from "../reusables/Guidelines";

function NameAndEmail({ handleNext, handlePrevious, handleChange, formData, showError, errorMessage }) {
  const [showGuidelines, setShowGuidelines] = useState(false);
  const [guidelinesTitle, setGuidelinesTitle] = useState("");
  const [guidelinesContent, setGuidelinesContent] = useState("");

  const navigate = useNavigate();

  const handleShowGuidelines = (title, content) => {
    setGuidelinesTitle(title);
    setGuidelinesContent(content);
    setShowGuidelines(true);
  };

  const handleCloseGuidelines = () => {
    setShowGuidelines(false);
  };
  return (
    <>
      <div className="main-container">
        <div className="container-info">
          <div className="header-container">
            <FontAwesomeIcon className="back-icon" icon={faArrowLeft} onClick={() => navigate("/")} />
            <h2 className="signup-header">Name and email</h2>
          </div>
          <DashedProgressBar activeIndex={1} />
          <form onSubmit={handleNext}>
            <div className="input-container">
              <input
                type="text"
                placeholder="Full Name"
                name="name"
                onChange={handleChange}
                defaultValue={formData.name}
                required
                className="text-input-primary"
              />
              <input
                type="text"
                placeholder="School Email"
                name="email"
                onChange={handleChange}
                defaultValue={formData.email}
                required
                className="text-input-primary"
              />
            </div>
            {showError && <Error message={errorMessage} />}
            <button className="sign-up-continue">Continue</button>
          </form>
        </div>
        <div className="terms-of-services">
          <span>
            By clicking continue, you agree to our
            <p>
              <a href="#" onClick={() => handleShowGuidelines("Terms of Service", TermsOfServiceContent)}>
                {" "}
                Terms of Services
              </a>{" "}
              and{" "}
              <a href="#" onClick={() => handleShowGuidelines("Privacy Policy", PrivacyPolicyContent)}>
                Privacy Policy
              </a>
            </p>
          </span>
        </div>
      </div>
      {showGuidelines && (
        <Guidelines title={guidelinesTitle} content={guidelinesContent} onClose={handleCloseGuidelines} />
      )}
    </>
  );
}

export default NameAndEmail;
