import { React, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCircleUser, faComments, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import "./styles/webnav.css";

export default function WebNavigation({ onLinkClick }) {
  const [activeLink, setActiveLink] = useState("Account");

  const handleLinkClick = (link) => {
    setActiveLink(link);
    onLinkClick(link);
  };

  return (
    <div>
      <nav className="web-landing-nav">
        <ul>
          <div className={activeLink === "Account" ? "active" : ""} onClick={() => handleLinkClick("Account")}>
            <FontAwesomeIcon className="web-navbar-icon" icon={faCircleUser} />
            <li>Profile</li>
          </div>
          <div className={activeLink === "Suggestions" ? "active" : ""} onClick={() => handleLinkClick("Suggestions")}>
            <FontAwesomeIcon className="web-navbar-icon" icon={faMagnifyingGlass} />
            <li>Suggestions</li>
          </div>
          <div className={activeLink === "Messages" ? "active" : ""} onClick={() => handleLinkClick("Messages")}>
            <FontAwesomeIcon className="web-navbar-icon" icon={faComments} />
            <li>Chats</li>
          </div>
          <div className={activeLink === "Likes" ? "active" : ""} onClick={() => handleLinkClick("Likes")}>
            <FontAwesomeIcon className="web-navbar-icon" icon={faThumbsUp} />
            <li>Likes</li>
          </div>
        </ul>
      </nav>
    </div>
  );
}
