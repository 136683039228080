import React, { createContext, useContext, useState } from "react";

const SignUpContext = createContext();

export const useSignUp = () => useContext(SignUpContext);

export const SignUpProvider = ({ children }) => {
  const [step, setStep] = useState(1);
  const [passProgress, setProgress] = useState(0);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirm_password: "",
    code: "",
    passProgress: "",
  });

  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);
  const updateFormData = (data) => setFormData((prev) => ({ ...prev, ...data }));

  return (
    <SignUpContext.Provider value={{ step, passProgress, setProgress, nextStep, prevStep, formData, updateFormData }}>
      {children}
    </SignUpContext.Provider>
  );
};
