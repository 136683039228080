import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./styles/guidelines.css";

const TermsOfServiceContent = `
  <h3>Effective Date: [Insert Date]</h3>
  <p>
    Thank you for visiting [Your Company Name]. Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [URL] (the "Site"). Please read this policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the Site.
  </p>
  <h4>Information We Collect</h4>
  <ul>
    <li><b>Personal Information:</b> Name, email address, phone number, postal address, or other similar identifiers.</li>
    <li><b>Payment Information:</b> Credit card number, billing address, and other payment details.</li>
    <li><b>Communications:</b> Correspondence between you and us, such as emails or messages sent through the Site.</li>
    <li><b>Usage Data:</b> Information about how you navigate and use the Site, including your IP address, browser type, pages visited, and referring pages.</li>
  </ul>
  <h4>How We Use Your Information</h4>
  <p>We may use the information we collect for various purposes, including to:</p>
  <ul>
    <li>Provide, operate, and maintain our Site;</li>
    <li>Improve, personalize, and expand our Site's functionality;</li>
    <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide updates and other information relating to the Site, and for marketing and promotional purposes;</li>
    <li>Process your transactions and manage your orders;</li>
    <li>Protect, investigate, and deter against unauthorized or illegal activities.</li>
  </ul>
  <h4>Sharing Your Information</h4>
  <p>
    We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, as long as those parties agree to keep this information confidential.
  </p>
  <h4>Security of Your Information</h4>
  <p>
    We implement security measures designed to protect your information from unauthorized access and disclosure. However, no data transmission over the Internet or method of electronic storage is completely secure. Therefore, while we strive to use commercially acceptable means to protect your information, we cannot guarantee its absolute security.
  </p>
  <h4>Your Choices</h4>
  <p>You have the right to access, update, or delete your personal information. You can also unsubscribe from our promotional emails at any time by following the instructions in those emails.</p>
  <h4>Children's Privacy</h4>
  <p>
    Our Site is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we learn that we have collected personal information from a child under 13, we will promptly delete that information.
  </p>
  <h4>Changes to This Privacy Policy</h4>
  <p>
    We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
  </p>
  <h4>Contact Us</h4>
  <p>If you have any questions about this Privacy Policy, please contact us at [Your Contact Information].</p>
`;

const PrivacyPolicyContent = `
  <h3>Effective Date: [Insert Date]</h3>
  <p>
    Thank you for visiting [Your Company Name]. Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [URL] (the "Site"). Please read this policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the Site.
  </p>
  <h4>Information We Collect</h4>
  <ul>
    <li><b>Personal Information:</b> Name, email address, phone number, postal address, or other similar identifiers.</li>
    <li><b>Payment Information:</b> Credit card number, billing address, and other payment details.</li>
    <li><b>Communications:</b> Correspondence between you and us, such as emails or messages sent through the Site.</li>
    <li><b>Usage Data:</b> Information about how you navigate and use the Site, including your IP address, browser type, pages visited, and referring pages.</li>
  </ul>
  <h4>How We Use Your Information</h4>
  <p>We may use the information we collect for various purposes, including to:</p>
  <ul>
    <li>Provide, operate, and maintain our Site;</li>
    <li>Improve, personalize, and expand our Site's functionality;</li>
    <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide updates and other information relating to the Site, and for marketing and promotional purposes;</li>
    <li>Process your transactions and manage your orders;</li>
    <li>Protect, investigate, and deter against unauthorized or illegal activities.</li>
  </ul>
  <h4>Sharing Your Information</h4>
  <p>
    We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, as long as those parties agree to keep this information confidential.
  </p>
  <h4>Security of Your Information</h4>
  <p>
    We implement security measures designed to protect your information from unauthorized access and disclosure. However, no data transmission over the Internet or method of electronic storage is completely secure. Therefore, while we strive to use commercially acceptable means to protect your information, we cannot guarantee its absolute security.
  </p>
  <h4>Your Choices</h4>
  <p>You have the right to access, update, or delete your personal information. You can also unsubscribe from our promotional emails at any time by following the instructions in those emails.</p>
  <h4>Children's Privacy</h4>
  <p>
    Our Site is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we learn that we have collected personal information from a child under 13, we will promptly delete that information.
  </p>
  <h4>Changes to This Privacy Policy</h4>
  <p>
    We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
  </p>
  <h4>Contact Us</h4>
  <p>If you have any questions about this Privacy Policy, please contact us at [Your Contact Information].</p>
`;

const Guidelines = ({ title, content, onClose }) => {
  return (
    <div className="guidelines-overlay">
      <div className="guidelines-modal">
        <div className="guidelines-header">
          <h2>{title}</h2>
          <button className="guidelines-close" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="guidelines-content" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};

export { Guidelines, TermsOfServiceContent, PrivacyPolicyContent };
