import React, { useState } from "react";
import { useOnboarding } from "./OnboardingContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropertyPhotosUpload from "./PropertyPhotos";
import { faDollarSign, faCirclePlus, faChevronDown, faBed, faBath } from "@fortawesome/free-solid-svg-icons";
import "./styles/propertyinfo.css";
import DropDown from "../reusables/DropDown";
import ToggleSwitch from "../reusables/ToggleSwitch";
import axios from "axios";

const api = process.env.REACT_APP_API;

function stepOneFieldsFilled(onboardingData) {
  const required = ["age", "city_of_origin", "program", "university", "picture"];
  return required.every((key) => onboardingData[key] !== undefined && onboardingData[key] !== "");
}

export default function PropertyInfo() {
  const { onboardingData, updateOnboardingData } = useOnboarding();
  const [showNext, setNext] = useState(false);
  const [photos, setPropertyPhotos] = useState({});

  const handleShow = () => {
    setNext(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    updateOnboardingData({ [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const photoUrls = Object.values(photos);
      const propertyData = { ...onboardingData, photos: photoUrls };
      const response = await axios.post(`${api}/onboarding/update-property`, { property_data: propertyData });
      if (response.status === 200) {
      } else {
      }
    } catch (error) {
      console.error("Error submitting property data:", error);
    }
  };

  return (
    <>
      {showNext ? (
        <PropertyInfoExtended className="property-info-step-two" handleSubmit={handleSubmit} />
      ) : (
        <div className="property-info-step-one">
          <h1 className="page-header">Property Info</h1>
          <div className="prompt" style={{ fontSize: "17px" }}>
            Tell us more about your current property
          </div>
          <div className="photo-upload-container">
            <PropertyPhotosUpload
              photos={photos}
              setPropertyPhotos={setPropertyPhotos}
              updateOnboardingData={updateOnboardingData}
            />
          </div>
          <div className="property-details-container">
            <input name="title" onChange={handleChange} type="text" placeholder="Title" />
            <input name="location" onChange={handleChange} type="text" placeholder="Location" />
            <div className="column-split-input">
              <input
                name="capacity"
                onChange={handleChange}
                className="mates-wanted"
                type="number"
                placeholder="Roommates Wanted"
              />
              <div className="cost-input-wrapper">
                <input name="cost" onChange={handleChange} className="room-cost" type="number" placeholder="Cost" />
                <FontAwesomeIcon icon={faDollarSign} className="dollar-icon" />
              </div>
            </div>
            <div className="address">
              <p>Privatize the property address</p>
              <div>
                <ToggleSwitch toggleName="private_location" />
              </div>
            </div>
          </div>
          <div className="role-select-footer">
            {stepOneFieldsFilled(onboardingData) && (
              <button onClick={handleShow} style={{ marginTop: 0 }} className="sign-up-continue">
                Continue
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

function stepTwoFieldsFilled(onboardingData) {
  const required = ["description", "bedrooms", "bathrooms", "property_type", "amenities"];
  return required.every(
    (key) => onboardingData[key] !== undefined && onboardingData[key] !== "" && onboardingData[key].length > 0
  );
}

function PropertyInfoExtended({ handleSubmit }) {
  const { nextStep, onboardingData, updateOnboardingData } = useOnboarding();
  const [viewMore, setViewMore] = useState(false);
  const [selectedAmenities, setSelectedAmenities] = useState([]);

  const propertyTypes = ["House", "Apartment", "Condo"];
  const amenities = [
    "Heater",
    "Pets",
    "Wifi",
    "Washer",
    "Gym",
    "Pool",
    "Parking",
    "Furnished",
    "AC",
    "Balcony",
    "Elevator",
    "Dishwasher",
    "Dryer",
  ]; // Curate list and fetch dynamically

  const handleChange = (event) => {
    const { name, value } = event.target;
    updateOnboardingData({ [name]: value });
  };

  const typeChoices = () => {
    return propertyTypes.map((type, index) => (
      <option key={index} value={type}>
        {type}
      </option>
    ));
  };

  const toggleViewMore = () => {
    setViewMore(!viewMore);
  };

  const handleAmenityClick = (amenity) => {
    let updatedAmenities;
    if (selectedAmenities.includes(amenity)) {
      updatedAmenities = selectedAmenities.filter((item) => item !== amenity);
    } else {
      updatedAmenities = [...selectedAmenities, amenity];
    }
    setSelectedAmenities(updatedAmenities);
    updateOnboardingData({ amenities: updatedAmenities });
  };

  const handleNext = () => {
    handleSubmit();
    nextStep();
  };

  return (
    <div className="property-info-step-two">
      <h1 className="page-header">Property Info</h1>
      <div className="property-details-container">
        <textarea
          onChange={handleChange}
          className="description"
          name="description"
          placeholder="Description"
        ></textarea>
        <div className="property-rooms">
          <div className="icon-wrapper">
            <input type="number" name="bedrooms" placeholder="Bedrooms" onChange={handleChange} />
            <FontAwesomeIcon icon={faBed} className="left-side-icon" />
          </div>
          <div className="icon-wrapper">
            <input type="number" name="bathrooms" placeholder="Bathrooms" onChange={handleChange} />
            <FontAwesomeIcon icon={faBath} className="left-side-icon" />
          </div>
        </div>
        <DropDown
          name="property_type"
          updateOnboardingData={updateOnboardingData}
          placeholder="Type of Property"
          content={typeChoices()}
        />
        <div className="amenities">
          <div className="amenitites-header">Amenities</div>
          <div className="amenities-grid">
            {amenities.slice(0, viewMore ? amenities.length : 3).map((amenity, index) => {
              const isSelected = selectedAmenities.includes(amenity);
              return (
                <div
                  key={index}
                  className={`amenity ${isSelected ? "selected" : ""}`}
                  onClick={() => handleAmenityClick(amenity)}
                >
                  <FontAwesomeIcon className="add-amenity" icon={faCirclePlus} />
                  <div className="amenity-name">{amenity}</div>
                </div>
              );
            })}
          </div>
          <button className="view-more-btn" onClick={toggleViewMore}>
            {viewMore ? "View Less" : "View More"}{" "}
            <FontAwesomeIcon icon={faChevronDown} className={`dropdown-arrow ${viewMore ? "open" : ""}`} />
          </button>
        </div>
      </div>
      <div className="role-select-footer">
        {stepTwoFieldsFilled(onboardingData) && (
          <button onClick={handleNext} style={{ marginTop: 0 }} className="sign-up-continue">
            Complete
          </button>
        )}
      </div>
    </div>
  );
}
