import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash, faEye } from "@fortawesome/free-regular-svg-icons";

import DashedProgressBar from "../reusables/DashedProgress";
import ProgressBar from "../reusables/ProgressBar";
import RequirementItem from "./RequirementItem";
import Error from "../reusables/Error";
import { Guidelines, TermsOfServiceContent, PrivacyPolicyContent } from "../reusables/Guidelines";

const Password = ({ handleNext, handlePrevious, handleChange, formData, passProgress, showError, errorMessage }) => {
  const [showGuidelines, setShowGuidelines] = useState(false);
  const [guidelinesTitle, setGuidelinesTitle] = useState("");
  const [guidelinesContent, setGuidelinesContent] = useState("");

  const togglePasswordVisibility = (e, show) => {
    const element = e.target.parentElement;
    const currentInput = document.getElementsByName(element.getAttribute("data-name"))[0];
    const confirm = document.getElementById("confirm_pass");

    const inputType = show ? "text" : "password";
    currentInput.setAttribute("type", inputType);
    confirm.setAttribute("type", inputType);

    const eye = document.getElementById("eye");
    const slash = document.getElementById("slash");

    if (show) {
      slash.style.display = "none";
      eye.style.display = "block";
    } else {
      slash.style.display = "block";
      eye.style.display = "none";
    }
  };

  const isRequirementMet = (requirement) => {
    switch (requirement) {
      case "length":
        return formData.password.length >= 8;
      case "number":
        return /\d/.test(formData.password);
      case "capital":
        return /[A-Z]/.test(formData.password);
      case "symbol":
        return /[!@#$%_]/.test(formData.password);
      default:
        return false;
    }
  };

  const handleShowGuidelines = (title, content) => {
    setGuidelinesTitle(title);
    setGuidelinesContent(content);
    setShowGuidelines(true);
  };

  const handleCloseGuidelines = () => {
    setShowGuidelines(false);
  };

  return (
    <>
      <div className="main-container">
        <div className="container-info">
          <div className="header-container">
            <FontAwesomeIcon className="back-icon" icon={faArrowLeft} onClick={handlePrevious} />
            <h2 className="signup-header">Set password</h2>
          </div>
          <DashedProgressBar activeIndex={2} />
          <form onSubmit={handleNext}>
            <div className="input-container">
              <div className="input-wrapper">
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={handleChange}
                  defaultValue={formData.password}
                  required
                  className="text-input-primary"
                />
                <div data-name="password" className="icon">
                  <FontAwesomeIcon
                    id="slash"
                    data-testid="slash"
                    data-name="password"
                    onMouseDown={(e) => togglePasswordVisibility(e, true)}
                    onTouchStart={(e) => togglePasswordVisibility(e, true)}
                    icon={faEyeSlash}
                  />
                  <FontAwesomeIcon
                    style={{ display: "none" }}
                    id="eye"
                    data-testid="eye"
                    data-name="password"
                    icon={faEye}
                    onMouseUp={(e) => togglePasswordVisibility(e, false)}
                    onTouchEnd={(e) => togglePasswordVisibility(e, false)}
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <input
                  id="confirm_pass"
                  type="password"
                  placeholder="Confirm Password"
                  name="confirm_password"
                  onChange={handleChange}
                  defaultValue={formData.confirm_password}
                  required
                  className="text-input-primary"
                />
              </div>
            </div>
            {showError && <Error message={errorMessage} />}
            <ProgressBar progress={passProgress} />
            <div className="requirements">
              <RequirementItem met={isRequirementMet("length")} text="8 characters minimum" />
              <RequirementItem met={isRequirementMet("number")} text="at least 1 number" />
              <RequirementItem met={isRequirementMet("capital")} text="at least 1 capital" />
              <RequirementItem met={isRequirementMet("symbol")} text="at least 1 symbol (!@#$%_)" />
            </div>
            <button className="sign-up-continue">Continue</button>
          </form>
        </div>
        <div style={{ marginBlock: "5%" }} className="terms-of-services">
          <span>
            By clicking continue, you agree to our
            <p>
              <a href="#" onClick={() => handleShowGuidelines("Terms of Service", TermsOfServiceContent)}>
                {" "}
                Terms of Services
              </a>{" "}
              and{" "}
              <a href="#" onClick={() => handleShowGuidelines("Privacy Policy", PrivacyPolicyContent)}>
                Privacy Policy
              </a>
            </p>
          </span>
        </div>
      </div>
      {showGuidelines && (
        <Guidelines title={guidelinesTitle} content={guidelinesContent} onClose={handleCloseGuidelines} />
      )}
    </>
  );
};

export default Password;
