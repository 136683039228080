import React, { createContext, useState, useContext } from "react";

const OnboardingContext = createContext();

export const OnboardingProvider = ({ children }) => {
  const [onboardingData, setOnboardingData] = useState({});
  const [currentStep, setCurrentStep] = useState(1);

  const updateOnboardingData = (newData) => {
    setOnboardingData((prevData) => ({ ...prevData, ...newData }));
  };

  const nextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 5));
  };

  return (
    <OnboardingContext.Provider value={{ onboardingData, updateOnboardingData, currentStep, nextStep }}>
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = () => useContext(OnboardingContext);
