import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Error from "../reusables/Error";
import { Guidelines, TermsOfServiceContent, PrivacyPolicyContent } from "../reusables/Guidelines";
import axios from "axios";

const api = `${process.env.REACT_APP_API}`;

function Login() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showPage, setShowPage] = useState(false);
  const [showGuidelines, setShowGuidelines] = useState(false);
  const [guidelinesTitle, setGuidelinesTitle] = useState("");
  const [guidelinesContent, setGuidelinesContent] = useState("");

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get(api + "/check-auth", { withCredentials: true });
        if (response.data.status === 200) {
          setIsAuthenticated(true);
          navigate("/onboarding");
        }
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
        setShowPage(true);
      }
    };

    checkAuth();
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    const request_body = {
      email: formData.email,
      password: formData.password,
    };

    try {
      const response = await axios.post(api + "/login", request_body);

      if (response.data.status === 200) {
        setLoginError(false);
        navigate("/onboarding");
      } else {
        setLoginErrorMessage(response.data.message);
        setLoginError(true);
      }
    } catch (error) {
      setLoginErrorMessage("Email or password are incorrect");
      setLoginError(true);
    }
  };

  const handleShowGuidelines = (title, content) => {
    setGuidelinesTitle(title);
    setGuidelinesContent(content);
    setShowGuidelines(true);
  };

  const handleCloseGuidelines = () => {
    setShowGuidelines(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!showPage) {
    return null;
  }

  return (
    <>
      <div className="main-container">
        <div className="container-info">
          <div className="header-container">
            <FontAwesomeIcon className="back-icon" icon={faArrowLeft} onClick={() => navigate("/")} />
            <h2 className="signup-header">Login</h2>
          </div>
          <form onSubmit={handleLogin}>
            <div className="input-container">
              <input
                type="text"
                placeholder="School Email"
                name="email"
                onChange={handleChange}
                value={formData.email}
                required
                className="text-input-primary"
              />
              <input
                type="password"
                placeholder="Password"
                name="password"
                onChange={handleChange}
                value={formData.password}
                required
                className="text-input-primary"
              />
            </div>
            {loginError && <Error message={loginErrorMessage} />}
            <button className="sign-up-continue">Login</button>
          </form>
        </div>
        <div className="terms-of-services">
          <span>
            By clicking continue, you agree to our
            <p>
              <a href="#" onClick={() => handleShowGuidelines("Terms of Service", TermsOfServiceContent)}>
                {" "}
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="#" onClick={() => handleShowGuidelines("Privacy Policy", PrivacyPolicyContent)}>
                Privacy Policy
              </a>
            </p>
          </span>
        </div>
      </div>
      {showGuidelines && (
        <Guidelines title={guidelinesTitle} content={guidelinesContent} onClose={handleCloseGuidelines} />
      )}
    </>
  );
}

export default Login;
