import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSignUp } from "./SignUpContext";
import { checkPassword, validName, validEmail } from "../../utilities/authentication";
import axios from "axios";
import NameAndEmail from "./NameAndEmail";
import Password from "./Password";
import Verify from "./Verify";
import Success from "./Success";
import "./styles/signup.css";

const api = `${process.env.REACT_APP_API}`;

function SignUp() {
  const navigate = useNavigate();
  const { step, passProgress, setProgress, nextStep, prevStep, formData, updateFormData } = useSignUp();

  const [nameError, showNameError] = useState("");
  const [nameErrMsg, setNameMsg] = useState("");

  const [passError, showPassError] = useState("");
  const [passErrMsg, setPassMsg] = useState("");

  const [verError, showVerError] = useState("");
  const [verErrMsg, setVerMsg] = useState("");

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showPage, setShowPage] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get(api + "/check-auth", { withCredentials: true });
        if (response.data.status === 200) {
          setIsAuthenticated(true);
          navigate("/onboarding");
        }
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
        setShowPage(true);
      }
    };

    checkAuth();
  }, [navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/onboarding");
    }
  }, [isAuthenticated, navigate]);

  const generateCode = async () => {
    const request_body = {
      email: formData.email,
      name: formData.name,
      password: formData.password,
    };

    try {
      const response = await axios.post(api + "/signup/send-auth-code", request_body);

      if (response.data.status == 200) {
        showVerError(false);
      } else {
        setVerMsg(response.data.message);
        showVerError(true);
      }
    } catch (error) {
      setVerMsg("An error occurred while generating the code.");
      showVerError(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "password") {
      setProgress(checkPassword(value));
    }

    updateFormData({ [name]: value });
  };

  const handleNext = async (e) => {
    e.preventDefault();

    if (step === 1) {
      if (!validName(formData.name)) {
        setNameMsg("Your name must not contain any symbols or numbers.");
        showNameError(true);

        return;
      }

      if (!validEmail(formData.email)) {
        setNameMsg("Your email must be a university email.");
        showNameError(true);
      } else {
        const request_body = { email: formData.email };

        const response = await axios.post(api + "/signup/validate-email", request_body);

        if (response.data.status === 200) {
          showNameError(false);

          nextStep();
        } else {
          setNameMsg(response.data.message);

          showNameError(true);
        }
      }
    }

    if (step === 2) {
      if (passProgress !== 4) {
        setPassMsg("Password does not meet requirements.");
        showPassError(true);

        return;
      }

      if (formData.password !== formData.confirm_password) {
        setPassMsg("The passwords you have entered do not match. Please try again.");

        showPassError(true);
      } else {
        generateCode();

        nextStep();
      }
    }
  };

  const handlePrevious = () => {
    prevStep();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!showPage) {
    return null;
  }

  return (
    <div>
      {step === 1 && (
        <NameAndEmail
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          handleChange={handleChange}
          formData={formData}
          showError={nameError}
          errorMessage={nameErrMsg}
        />
      )}
      {step === 2 && (
        <Password
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          handleChange={handleChange}
          formData={formData}
          passProgress={passProgress}
          showError={passError}
          errorMessage={passErrMsg}
        />
      )}
      {step === 3 && (
        <Verify
          nextStep={nextStep}
          formData={formData}
          setError={showVerError}
          setMessage={setVerMsg}
          showError={verError}
          errorMessage={verErrMsg}
        />
      )}
      {step === 4 && <Success />}
    </div>
  );
}

export default SignUp;
