import React from "react";
import axios from "axios";
import "./styles/landing.css";

const api = `${process.env.REACT_APP_API}`;

function WebLanding() {
  const sendEmail = async () => {
    const emailInput = document.querySelector(".email-input");
    const email = emailInput.value;
    const request_body = {
      email: email,
    };

    try {
      const response = await axios.post(api + "/store-email", request_body);

      if (response.data.status === 200) {
        console.log("Email sent");
        emailInput.value = ""; // Clear the input field after a successful submission
      } else {
        console.log("Email not sent");
      }
    } catch (error) {
      console.log("Email not sent");
    }
  };

  return (
    <div className="web-landing">
      <header className="landing-header">
        <h1 className="landing-title">LEAZY</h1>
        <nav className="landing-nav"></nav>
      </header>

      <div className="main-content">
        <div className="left-section">
          <h2 className="headline">
            <strong className="highlighted">Find</strong> your place. <strong className="highlighted">Find</strong> your
            people.
          </h2>
          <h2 className="motto">Join us to stay updated</h2>
          <div className="newsletter-section">
            <input type="email" placeholder="Enter Your Email Address" className="email-input" />
            <button className="subscribe-button" onClick={sendEmail}>
              Subscribe
            </button>
          </div>
        </div>

        <div className="screenshots">
          <img src={`${process.env.PUBLIC_URL}/images/onboarding.png`} alt="Sign Up" className="screenshot" />
          <img src={`${process.env.PUBLIC_URL}/images/suggestions.png`} alt="Onboard" className="screenshot" />
          <img src={`${process.env.PUBLIC_URL}/images/listings.png`} alt="Find Roommate" className="screenshot" />
        </div>
      </div>

      <footer className="landing-footer">
        <p>© 2024 Leazy. All rights reserved</p>
      </footer>
    </div>
  );
}

export default WebLanding;
