import { React } from "react";

export default function Chats() {
  return (
    <div className="feature-modal-overlay">
      <div className="feature-modal">
        <div className="feature-modal-header">Feature in Progress</div>
        <div className="feature-modal-body">Sorry, this feature is currently in development.</div>
      </div>
    </div>
  );
}
