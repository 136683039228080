import React from "react";

import "./styles/requirements.css";

function RequirementItem({ met, text }) {
  return (
    <div className="requirement-item">
      <div className={`circle ${met ? "met" : ""}`}>
        {met && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="checkmark"
          >
            <path d="M20 6L9 17l-5-5" />
          </svg>
        )}
      </div>
      <span>{text}</span>
    </div>
  );
}

export default RequirementItem;
