export const checkPassword = (value) => {
  let progress = 0;

  if (value.length >= 8) progress += 1;
  if (/[!@#$%_]/.test(value)) progress += 1;
  if (/\d/.test(value)) progress += 1;
  if (/[A-Z]/.test(value)) progress += 1;

  return progress;
};

export const validName = (name) => {
  return /^[A-Za-z\s]+$/.test(name);
};

export const validEmail = (email) => {
  // in the future, we want to test all types of domains not just uwo
  return /@uwo\.ca$/.test(email);
};
