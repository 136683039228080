import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useOnboarding } from "./OnboardingContext";
import RoleSelect from "./RoleSelect";
import DataConsent from "../reusables/DataConsent";
import PersonalInfo from "./PersonalInfo";
import PropertyInfo from "./PropertyInfo";

import Dashboard from "../dashboards/Dashboard";

import "./styles/onboarding.css";

const api = `${process.env.REACT_APP_API}`;

function OnBoardingProgress({ progress, role }) {
  let getWidth = () => `${(progress / 3) * 100}%`;

  if (role) {
    if (role == "searcher") {
      getWidth = () => `${(progress / 3) * 100}%`;
    } else {
      getWidth = () => `${(progress / 4) * 100}%`;
    }
  }

  return (
    <div className="onboarding-progress-bar-container">
      <div className="progress-bar-filler" style={{ width: getWidth(), backgroundColor: "black" }}></div>
    </div>
  );
}

function Onboarding() {
  const { onboardingData, currentStep } = useOnboarding();
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showPage, setShowPage] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkOnboarded = async () => {
      try {
        const { data } = await axios.get(api + "/onboarding/onboarded", {
          withCredentials: true,
        });
        if (data.onboarded.onboarded == true) {
          navigate("/home/dashboard");
        }
      } catch (error) {
        console.error("Error checking onboarding status:", error);
      } finally {
        setLoading(false);
        setShowPage(true);
      }
    };

    checkOnboarded();
  }, [navigate]);

  useEffect(() => {
    if (onboardingData.role && currentStep > 3) {
      if (onboardingData.role === "searcher" && currentStep == 4) {
        setShow(false);
        completeOnboarding();
      }
      if (onboardingData.role === "poster" && currentStep == 5) {
        setShow(false);
        completeOnboarding();
      }
    } else {
      setShow(true);
    }
  }, [currentStep, onboardingData.role]);

  const completeOnboarding = async () => {
    try {
      const response = await axios.post(`${api}/onboarding/complete-onboarding`, {}, { withCredentials: true });
      if (response.data.status === 200) {
        navigate("/home/dashboard");
      }
    } catch (error) {
      console.error("Error completing onboarding:", error);
    }
  };

  const step = () => {
    switch (currentStep) {
      case 1:
        return <RoleSelect />;
      case 2:
        return <DataConsent />;
      case 3:
        return <PersonalInfo />;
      case 4:
        if (onboardingData.role === "poster") {
          return <PropertyInfo />;
        }
        break;
      default:
        return null;
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!showPage) {
    return null;
  }

  return (
    <>
      {show ? (
        <div className="onboarding-container">
          <OnBoardingProgress progress={currentStep} role={onboardingData.role} />
          <div>{step()}</div>
        </div>
      ) : (
        <Dashboard />
      )}
    </>
  );
}

export default Onboarding;
