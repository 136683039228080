import React, { useState } from "react";
import CroppingModal from "../reusables/CroppingModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import supabase from "../../supabase/SupabaseClient";
import "./styles/personalinfo.css";

const ProfileUpload = ({ updateOnboardingData }) => {
  const [image, setImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);

  const emptyImage = image === null;

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
        setShowModal(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleIconClick = () => {
    if (emptyImage) {
      document.getElementById("fileInput").click();
    }
  };

  const handleCloseModal = () => {
    if (emptyImage) {
      setImageSrc(null);
    }
    setShowModal(false);
  };

  const handleCrop = async (croppedImage) => {
    try {
      const imageBlob = await (await fetch(croppedImage)).blob();
      const fileExt = imageBlob.type.split("/")[1];
      const fileName = `${Date.now()}.${fileExt}`;
      const { data, error } = await supabase.storage.from("profile_pictures").upload(`public/${fileName}`, imageBlob);

      if (error) throw error;

      const publicUrl = supabase.storage.from("profile_pictures").getPublicUrl(`public/${fileName}`).data.publicUrl;
      setImage(publicUrl);
      updateOnboardingData({ picture: publicUrl });
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setShowModal(false);
    }
  };

  return (
    <div className="profile-upload-container">
      <input id="fileInput" type="file" style={{ display: "none" }} accept="image/*" onChange={handleImageChange} />
      <div className="upload-circle" onClick={handleIconClick}>
        {image ? (
          <img src={image} alt="Profile" className="profile-upload-image" onClick={() => setShowModal(true)} />
        ) : (
          <FontAwesomeIcon icon={faUser} className="profile-upload-icon" />
        )}
      </div>
      <div className="upload-text">Upload a profile photo</div>
      {showModal && <CroppingModal imageSrc={imageSrc} onCrop={handleCrop} onClose={handleCloseModal} />}
    </div>
  );
};

export default ProfileUpload;
