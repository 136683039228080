import React, { useState } from "react";
import { useOnboarding } from "../onboarding/OnboardingContext";
import "./styles/toggle.css";

const ToggleSwitch = ({ toggleName }) => {
  const { onboardingData, updateOnboardingData } = useOnboarding();
  const [isOn, setIsOn] = useState(
    onboardingData[toggleName] === "True" || onboardingData[toggleName] === undefined
      ? false
      : onboardingData[toggleName] === "True"
  );

  const handleToggle = () => {
    const newState = !isOn;
    setIsOn(newState);
    updateOnboardingData({ [toggleName]: newState ? "True" : "False" });
  };

  return (
    <div className={`toggle-switch ${isOn ? "on" : "off"}`} onClick={handleToggle}>
      <div className="toggle-thumb"></div>
    </div>
  );
};

export default ToggleSwitch;
