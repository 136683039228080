import React from "react";
import axios from "axios";

import "./styles/landing.css";

function MobileLanding() {
  const api = `${process.env.REACT_APP_API}`;

  const sendEmail = async () => {
    const email = document.querySelector(".email-input-mobile").value;

    const request_body = {
      email: email,
    };

    try {
      const response = await axios.post(api + "/store-email", request_body);

      if (response.data.status === 200) {
        console.log("Email sent");
      } else {
        console.log("Email not sent");
      }
    } catch (error) {
      console.log("Email not sent");
    }
  };

  return (
    <div className="mobile-landing">
      <header className="mobile-header">
        <h1 className="mobile-title">LEAZY</h1>
      </header>
      <div className="mobile-content">
        <h2 className="mobile-headline">
          <strong className="highlighted">Find</strong> your place.
          <br />
          <strong className="highlighted">Find</strong> your people.
        </h2>
        <div className="newsletter-section-mobile">
          <input type="email" placeholder="Enter your email for updates" className="email-input-mobile" />
          <button className="subscribe-button-mobile" onClick={sendEmail}>
            Subscribe
          </button>
        </div>
      </div>
    </div>
  );
}

export default MobileLanding;
