import React, { useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./styles/cropping.css";

const CroppingModal = ({ imageSrc, onCrop, onClose }) => {
  const cropperRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleCrop = () => {
    const cropper = cropperRef.current.cropper;
    const croppedImage = cropper.getCroppedCanvas().toDataURL();
    onCrop(croppedImage);
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const cropper = cropperRef.current.cropper;
        cropper.replace(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChangeClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2 style={{ textAlign: "left" }}>Adjust Photo</h2>
          <button className="close-button" onClick={onClose}>
            ×
          </button>
        </div>
        <Cropper
          src={imageSrc}
          style={{ height: "50vh", width: "100%" }}
          aspectRatio={1}
          guides={false}
          ref={cropperRef}
          className="cropper-container"
          background={false}
          autoCropArea={1}
          viewMode={1}
          responsive={true}
        />
        <input type="file" accept="image/*" ref={fileInputRef} style={{ display: "none" }} onChange={handleChange} />
        <div className="modal-buttons">
          <button onClick={handleCrop} className="crop-button">
            Confirm
          </button>
          <button onClick={handleChangeClick} className="change-button">
            Change
          </button>
        </div>
      </div>
    </div>
  );
};

export default CroppingModal;
