import React, { useState } from "react";
import axios from "axios";
import { useOnboarding } from "./OnboardingContext";
import DropDown from "../reusables/DropDown";
import Slider from "../reusables/Slider";
import ProfileUpload from "./ProfileUpload";
import { Circles } from "./RoleSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./styles/personalinfo.css";

const api = `${process.env.REACT_APP_API}`;

function stepOneFieldsFilled(onboardingData) {
  const required = ["age", "city_of_origin", "program", "university", "picture"];
  return required.every((key) => onboardingData[key] !== undefined && onboardingData[key] !== "");
}

export default function PersonalInfo() {
  const { onboardingData, updateOnboardingData } = useOnboarding();
  const [showNext, setNext] = useState(false);

  const handleShow = () => {
    setNext(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    updateOnboardingData({ [name]: value });
  };

  const handleNext = async () => {
    try {
      const response = await axios.post(
        `${api}/onboarding/update-profile`,
        { profile_data: onboardingData },
        { withCredentials: true }
      );

      if (response.data.status === 200 || response.data.status === 201) {
        console.log(response.data.message);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const universities = ["Western University", "Huron College", "Brescia University College"];
  const programs = ["Business", "Engineering", "Science", "Medicine", "Nursing"];

  const universityChoices = () => {
    return universities.map((university, index) => (
      <option key={index} value={university}>
        {university}
      </option>
    ));
  };

  const programChoices = () => {
    return programs.map((program, index) => (
      <option key={index} value={program}>
        {program}
      </option>
    ));
  };

  return (
    <>
      {showNext ? (
        <PersonalInfoExtended className="personal-info-step-two" handleNext={handleNext} />
      ) : (
        <div className="personal-info-step-one">
          <h1 className="page-header">Getting Started</h1>
          <div className="personal-info-input-container">
            <ProfileUpload updateOnboardingData={updateOnboardingData} />
            <DropDown
              name={"university"}
              updateOnboardingData={updateOnboardingData}
              content={universityChoices()}
              placeholder="University"
            />
            <DropDown
              name={"program"}
              updateOnboardingData={updateOnboardingData}
              content={programChoices()}
              placeholder="Program"
            />
            <div className="custom-input">
              <input
                name="city_of_origin"
                onChange={handleChange}
                className="city-input"
                type="text"
                placeholder="City of Origin"
              />
              <input name="age" onChange={handleChange} className="age-input" type="number" placeholder="Age" />
            </div>
          </div>
          <div className="role-select-footer">
            {stepOneFieldsFilled(onboardingData) && (
              <div className="br-next-button">
                <FontAwesomeIcon className="next-button" onClick={handleShow} icon={faArrowRight} />
              </div>
            )}
            <Circles />
          </div>
        </div>
      )}
    </>
  );
}

function stepTwoFieldsFilled(onboardingData) {
  let required = ["gender", "sexual_orientation", "religion", "ethnicity", "budget"];
  if (onboardingData.role === "poster") {
    required.pop();
  }
  return required.every((key) => onboardingData[key] !== undefined && onboardingData[key] !== "");
}

function PersonalInfoExtended({ handleNext }) {
  const { nextStep, onboardingData, updateOnboardingData } = useOnboarding();

  const genders = ["Male", "Female", "Other"];
  const sexual_orientations = [
    "Straight",
    "Homosexual",
    "Bisexual",
    "Asexual",
    "Pansexual",
    "Queer",
    "Questioning",
    "Other",
  ];
  const religions = ["Christian", "Muslim", "Jewish", "Hindu", "Buddhist", "Atheist", "Agnostic", "Other"];
  const ethnicities = ["White", "Black", "Hispanic", "Asian", "Middle Eastern", "Indigenous", "Other"];

  const genderChoices = () => {
    return genders.map((choice, index) => (
      <option key={index} value={choice}>
        {choice}
      </option>
    ));
  };

  const sexChoices = () => {
    return sexual_orientations.map((choice, index) => (
      <option key={index} value={choice}>
        {choice}
      </option>
    ));
  };

  const religionChoices = () => {
    return religions.map((choice, index) => (
      <option key={index} value={choice}>
        {choice}
      </option>
    ));
  };

  const ethnicityChoices = () => {
    return ethnicities.map((choice, index) => (
      <option key={index} value={choice}>
        {choice}
      </option>
    ));
  };

  return (
    <>
      <h1 className="page-header">Personal Info</h1>
      <div className="reminder">
        <p>Please note some information will be used to provide you with the best suggestions</p>
      </div>
      <div className="personal-info-input-container-two">
        <DropDown
          name="gender"
          updateOnboardingData={updateOnboardingData}
          content={genderChoices()}
          placeholder="Gender"
        />
        <DropDown
          name={"sexual_orientation"}
          updateOnboardingData={updateOnboardingData}
          content={sexChoices()}
          placeholder="Sexual Orientation"
        />
        <DropDown
          name={"religion"}
          updateOnboardingData={updateOnboardingData}
          content={religionChoices()}
          placeholder="Religion"
        />
        <DropDown
          name={"ethnicity"}
          updateOnboardingData={updateOnboardingData}
          content={ethnicityChoices()}
          placeholder="Ethnicity"
        />
        {onboardingData.role === "searcher" && (
          <div className="budget-slider">
            <p>Budget</p>
            <Slider min={600} max={2000} />
          </div>
        )}
      </div>
      <div className="role-select-footer">
        {stepTwoFieldsFilled(onboardingData) && (
          <button
            onClick={() => {
              handleNext();
              nextStep();
            }}
            style={{ marginTop: 0 }}
            className="sign-up-continue"
          >
            Continue
          </button>
        )}
        <div>
          <Circles />
        </div>
      </div>
    </>
  );
}
