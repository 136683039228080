import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

import "./styles/error.css";

function Error({ message }) {
  return (
    <div className="error-container">
      <FontAwesomeIcon className="info-icon" icon={faCircleInfo} />
      <span>{message}</span>
    </div>
  );
}

export default Error;
