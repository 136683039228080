import React from "react";
import "./styles/progressbar.css";

const ProgressBar = ({ progress }) => {
  const getWidth = () => `${(progress / 4) * 100}%`;
  const getColor = () => {
    switch (progress) {
      case 1:
        return "#D62828";
      case 2:
        return "#D62828";
      case 3:
        return "#FFA500";
      case 4:
        return "#008000";
      default:
        return "#ededed";
    }
  };

  return (
    <div className="progress-bar-container">
      <div className="progress-bar-filler" style={{ width: getWidth(), backgroundColor: getColor() }}></div>
    </div>
  );
};

export default ProgressBar;
